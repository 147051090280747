import React,{ useState,useEffect } from 'react'
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { MoreVert ,Delete,Edit } from '@mui/icons-material';
import {LinearProgress} from '@mui/material';
import {FormControl,InputLabel,Select,Dialog,DialogActions,DialogTitle,DialogContent,Grid,Menu,MenuItem,IconButton,ListItemIcon,Divider,TextField} from '@mui/material';
import Slide from '@mui/material/Slide';
import Loader from '../../Tools/Loader';
import Alerts from '../../Tools/Alerts';
import { useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function StockItems() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openedit, setOpenedit] = useState(false)
  const location = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [value, setValue] = useState('');
  const [id_item, setIdItem] = useState('');
  const [id_merchant, setIdMerchant] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openbol = Boolean(anchorEl);
  const [alerto, setAlerto] = useState('');
  const [detail, setDetail] = useState('');
  
  const columns = [
	  { field: 'nomor', headerName: 'No', width: 50 },
	  
	  { field: 'stock_in', headerName: 'In', width: 150 },
	  { field: 'stock_out', headerName: 'Out', width: 150 },
	  { field: 'description', headerName: 'Description', width: 200 },
	  { field: 'createdate', headerName: 'Date', width: 200 },
	  { field: 'id', headerName: 'Action', width: 100,
			renderCell: (cellValues) => {
			  return (
				<>
					<IconButton color="primary" aria-label="More Option" 
							component="span"
							onClick={(event) => {
							handleClick(event, cellValues);
					  }}>
					  <MoreVert />
					</IconButton>
					<Menu
						id="account-menu"
						anchorEl={anchorEl}
						open={openbol}
						onClose={()=> setAnchorEl(null)}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<Divider/>
						
						<MenuItem onClick={()=> deleteselected(value,id_item,id_merchant)}>
							<ListItemIcon>
								<Delete fontSize="small" color="error" />
							</ListItemIcon>Delete
						</MenuItem>
					</Menu>
				</>
			  );
			}
	  },
	];
	const handleClick = (event, cellValues) => {
	  //console.log(cellValues.row.id);
	  setValue(cellValues.row.id)
	  setIdItem(cellValues.row.id_item)
	  setIdMerchant(cellValues.row.id_merchant)
	  setAnchorEl(event.currentTarget);
	};
	
	useEffect(() => {
		GetData()
    },[]); 
	const GetData = async () => {
		try {
			setLoading(true)
			const tokendata = await localStorage.getItem('TokenData')
			const headers_data = {
				Authorization: tokendata,
			}
			var api='https://node.ladokutu.info/index.php/Posc/stock_item_merchant_pos';  
			const response = await axios({
				method: 'post',
				headers: headers_data,
				url: api,
				data: {id_item : location.state.id_item,id_merchant : location.state.id_merchant }
				//withCredentials: true,
			});
			//console.log(response)
			setData(response.data)
			setLoading(false)
		} catch (e) {
			console.log(e)
		}
	}
	const SimpanData = async (event) => {
		setLoading(true)
		setOpen(false)
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		let type_send = data.get('type_send')
		let stock = data.get('stock')
		let description = data.get('description')
		try { 
			const tokendata = await localStorage.getItem('TokenData')
				const headers_data = {
					Authorization: tokendata,
				}
            var api='https://node.ladokutu.info/index.php/Posc/add_data_stock_item_merchant_pos';  
            const data_body = { 
					'id_item':location.state.id_item,
                    'type_send': type_send,
					'stock': stock,
					'description': description
                }
			const response = await axios({
                  method: 'post',
                  url: api,
				  headers: headers_data,
                  data: data_body
				});
			if(response.data.status===200){
				GetData()
			}
				setAlerto({
				  alerto: true,
				  state_msg: response.data.message,
				  state_typ:'success'
				});
				console.log(response.data.message)
			
			setLoading(false)
        } catch (error) {
			setAlerto({
				  alerto: true,
				  state_msg: 'Data Error',
				  state_typ:'error'
				});
            console.log(error)
			setLoading(false)
        }
	
	
  };
  const deleteselected = async (id,id_item,id_merchant) => {
			setAnchorEl(null)
			setLoading(true)
			try {
				const tokendata = await localStorage.getItem('TokenData')
				const headers_data = {
					Authorization: tokendata,
				}
				var api='https://node.ladokutu.info/index.php/Posc/delete_data_stock_item_merchant_pos';  
				const response = await axios({
					method: 'post',
					headers: headers_data,
					url: api,
					data: {id:id,id_item:id_item,id_merchant:id_merchant} 
				});
				
				if(response.data.status===200){
					GetData()
				}
				
				//console.log(selectedid)
				setLoading(false)
				setAlerto({
				  alerto: true,
				  state_msg: response.data.message,
				  state_typ:'success'
				});
			} catch (e) {
				setAlerto({
				  alerto: true,
				  state_msg: 'Data Error',
				  state_typ:'error'
				});
				console.log(e)
				setLoading(false)
			}
	};
	
	
	const handleCloseAlerto = () => {
		setAlerto(false);
	  };
	
	
  return (
    <>
	<Loader loading={loading} />
	<Alerts  alerto={alerto.alerto} state_typ={alerto.state_typ} state_msg={alerto.state_msg} onClose={handleCloseAlerto} />
	<div style={{ height: 500, width: '100%' }}>
	  <Box sx={{ '& button': { m: 1 } }}>
		  <div>
			<Button variant="outlined" onClick={()=> setOpen(true)}>Add Data</Button>
		  </div>
	  </Box>
      <DataGrid
	    loading={loading}
        rows={data}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
		  LoadingOverlay: LinearProgress,
        }}
		pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
      />
	</div>  
	  
	  <Dialog
        open={open}
		onClose={()=> setOpen(false)}
        TransitionComponent={Transition}
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle >Add Stock</DialogTitle>
		<Box component="form" onSubmit={SimpanData}  >
        <DialogContent dividers>
				
			
			 <Grid container spacing={2} >
				  <Grid item xs={12}>
						<FormControl fullWidth  size="small">
						  <InputLabel id="demo-simple-select-label">Type</InputLabel>
						  <Select
							labelId="demo-simple-select-label"
							id="type_send" name="type_send"
							label="Type" required
						  >
							<MenuItem value="stock_in">Stock In</MenuItem>
							<MenuItem value="stock_out">Stock Out</MenuItem>
						  </Select>
						</FormControl>
				  </Grid>
				  <Grid item xs={12}>
					<TextField margin="dense" required fullWidth size="small" name="stock" id="stock" label="Stock" autoComplete="stock" autoFocus/>
				  </Grid>
				  
				 
				  <Grid item xs={12}>
					<TextField margin="normal" required fullWidth size="small" 
					name="description" id="description" label="Description" 
					multiline rows={3}
					autoComplete="description" />
				  </Grid>
			</Grid>
			
			
        </DialogContent>
        <DialogActions>
			<Button variant="outlined" onClick={()=> setOpen(false)}>Close</Button>
			<Button color="success" variant="contained" type="submit">Save</Button>
        </DialogActions>
		</Box>
      </Dialog>
	  
	  
	  
	</>  
    
  );
}
