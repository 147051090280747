import React,{ useState,useEffect } from 'react'
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { MoreVert ,Delete,Edit,AllInbox } from '@mui/icons-material';
import {LinearProgress} from '@mui/material';
import {Dialog,DialogActions,DialogTitle,DialogContent,Grid,Menu,MenuItem,IconButton,ListItemIcon,Divider,TextField,FormControl,InputLabel,Select} from '@mui/material';
import Slide from '@mui/material/Slide';
import Loader from '../../Tools/Loader';
import Alerts from '../../Tools/Alerts';
import { useNavigate  } from 'react-router-dom'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function Items() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openedit, setOpenedit] = useState(false)
  const [pageSize, setPageSize] = useState(10);
  const [value, setValue] = useState('');
  const [id_item, setIdItem] = useState('');
  const [id_merchant, setIdMerchant] = useState('');
  const [datacategory, setDataCategory] = useState([]);
  const [dataunit, setDataUnit] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openbol = Boolean(anchorEl);
  const [alerto, setAlerto] = useState('');
  const [detail, setDetail] = useState('');
  const navigate = useNavigate();
  
  const columns = [
	  { field: 'nomor', headerName: 'No', width: 50 },
	  { field: 'category_name', headerName: 'Type', width: 200 },
	  { field: 'item_name', headerName: 'Name', width: 350 },
	  { field: 'on_stock', headerName: 'Stock', width: 200 },
	  { field: 'basic_price', headerName: 'Basic Price', width: 200 },
	  { field: 'sell_price', headerName: 'Sell Price', width: 200 },
	  { field: 'discount', headerName: 'Discount', width: 150 },
	  { field: 'tax', headerName: 'tax', width: 150 },
	  { field: 'id', headerName: 'Action', width: 100,
			renderCell: (cellValues) => {
			  return (
				<>
					<IconButton color="primary" aria-label="More Option" 
							component="span"
							onClick={(event) => {
							handleClick(event, cellValues);
					  }}>
					  <MoreVert />
					</IconButton>
					<Menu
							id="account-menu"
							anchorEl={anchorEl}
							open={openbol}
							onClose={()=> setAnchorEl(null)}
							MenuListProps={{
							  'aria-labelledby': 'basic-button',
							}}
					>
						<MenuItem onClick={()=> item_stock(id_item,id_merchant)}>
							<ListItemIcon>
								<AllInbox fontSize="small" color="info" />
							</ListItemIcon>Stock
						</MenuItem>
						<Divider/>
						<MenuItem onClick={()=> view_edit_data(id_item,id_merchant)}>
							<ListItemIcon>
								<Edit fontSize="small" color="warning" />
							</ListItemIcon>Edit
						</MenuItem>
						<MenuItem onClick={()=> deleteselected(id_item,id_merchant)}>
							<ListItemIcon>
								<Delete fontSize="small" color="error" />
							</ListItemIcon>Delete
						</MenuItem>
					</Menu>
				</>
			  );
			}
	  },
	];
	const handleClick = (event, cellValues) => {
	  //console.log(cellValues.row.id);
	  setValue(cellValues.row.id)
	  setIdItem(cellValues.row.id_item)
	  setIdMerchant(cellValues.row.id_merchant)
	  setAnchorEl(event.currentTarget);
	};
	
	useEffect(() => {
		GetData()
		GetDataCategory()
		GetDataUnit()
    },[]); 
	const GetDataCategory = async () => {
		try {
			setLoading(true)
			const tokendata = await localStorage.getItem('TokenData')
			const headers_data = {
				Authorization: tokendata,
			}
			var api='https://node.ladokutu.info/index.php/Posc/master_category_item_merchant_pos';  
			const response = await axios({
				method: 'post',
				headers: headers_data,
				url: api,
			});
			
			setDataCategory(response.data)
			setLoading(false)
		} catch (e) {
			console.log(e)
		}
	}
	const item_category=datacategory.map((item,index) =>(
		<MenuItem value={item.id_type}>{item.category_name}</MenuItem>
	))
	
	const GetDataUnit = async () => {
		try {
			setLoading(true)
			const tokendata = await localStorage.getItem('TokenData')
			const headers_data = {
				Authorization: tokendata,
			}
			var api='https://node.ladokutu.info/index.php/Posc/data_unit_type';  
			const response = await axios({
				method: 'post',
				headers: headers_data,
				url: api,
			});
			
			setDataUnit(response.data)
			setLoading(false)
		} catch (e) {
			console.log(e)
		}
	}
	
	const unit_type=dataunit.map((item,index) =>(
		<MenuItem value={item.id_unit}>{item.unit_name}</MenuItem>
	))
	const item_stock = (id_item,id_merchant) => {
		console.log('Check Stock ',id_item)
		navigate("/StockItems",{state: {id_item:id_item,id_merchant: id_merchant}} )
	};
	const GetData = async () => {
		try {
			setLoading(true)
			const tokendata = await localStorage.getItem('TokenData')
			const headers_data = {
				Authorization: tokendata,
			}
			var api='https://node.ladokutu.info/index.php/Posc/master_item_merchant_pos';  
			const response = await axios({
				method: 'post',
				headers: headers_data,
				url: api,
				//withCredentials: true,
			});
			//console.log(response)
			setData(response.data)
			setLoading(false)
		} catch (e) {
			console.log(e)
		}
	}
	const SimpanData = async (event) => {
		setLoading(true)
		setOpen(false)
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		let id_type = data.get('id_type')
		let id_unit = data.get('id_unit')
		let item_name = data.get('item_name')
		let description = data.get('description')
		let basic_price = data.get('basic_price')
		let sell_price = data.get('sell_price')
		let discount = data.get('discount')
		let tax = data.get('tax')

		try { 
			const tokendata = await localStorage.getItem('TokenData')
				const headers_data = {
					Authorization: tokendata,
				}
            var api='https://node.ladokutu.info/index.php/Posc/add_data_master_item_merchant_pos';  
            const data_body = { 
                    'id_type': id_type,
					'id_unit': id_unit,
					'item_name': item_name,
					'description': description,
					'basic_price': basic_price,
					'sell_price': sell_price,
					'discount': discount,
					'tax': tax
                }
			const response = await axios({
                  method: 'post',
                  url: api,
				  headers: headers_data,
                  data: data_body
				});
			if(response.data.status===200){
				GetData()
			}
				setAlerto({
				  alerto: true,
				  state_msg: response.data.message,
				  state_typ:'success'
				});
				console.log(response.data.message)
			
			setLoading(false)
        } catch (error) {
			
			setAlerto({
				  alerto: true,
				  state_msg: 'Data Error',
				  state_typ:'error'
				});
            console.log(error)
			setLoading(false)
        }
	
	
  };
  const deleteselected = async (id_item,id_merchant) => {
			setAnchorEl(null)
			setLoading(true)
			try {
				const tokendata = await localStorage.getItem('TokenData')
				const headers_data = {
					Authorization: tokendata,
				}
				var api='https://node.ladokutu.info/index.php/Posc/delete_data_master_item_merchant_pos';  
				const response = await axios({
					method: 'post',
					headers: headers_data,
					url: api,
					data: {id_item:id_item,id_merchant:id_merchant}
				});
				
				if(response.data.status===200){
					GetData()
				}
				
				//console.log(selectedid)
				setLoading(false)
				setAlerto({
				  alerto: true,
				  state_msg: response.data.message,
				  state_typ:'success'
				});
			} catch (e) {
				setAlerto({
				  alerto: true,
				  state_msg: 'Data Error',
				  state_typ:'error'
				});
				console.log(e)
				setLoading(false)
			}
	};
	const view_edit_data = async (id_item,id_merchant) => {
			setAnchorEl(null)
			setLoading(true)
			try {
				const tokendata = await localStorage.getItem('TokenData')
				const headers_data = {
					Authorization: tokendata,
				}
				var api='https://node.ladokutu.info/index.php/Posc/data_detail_item_merchant';  
				const response = await axios({
					method: 'post',
					headers: headers_data,
					url: api,
					data: {id_item:id_item,id_merchant:id_merchant} 
				});
				//console.log(response.data)
				setDetail(response.data)
				setOpenedit(true)
				setLoading(false)
			} catch (e) {
				
				console.log(e)
				setLoading(false)
			}
	};
	const EditData = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		let id_type = data.get('id_type')
		let id_unit = data.get('id_unit')
		let item_name = data.get('item_name')
		let description = data.get('description')
		let basic_price = data.get('basic_price')
		let sell_price = data.get('sell_price')
		let discount = data.get('discount')
		let tax = data.get('tax')
		
		setOpenedit(false)
		setLoading(true)
		try { 
			const tokendata = await localStorage.getItem('TokenData')
				const headers_data = {
					Authorization: tokendata,
				}
            var api='https://node.ladokutu.info/index.php/Posc/edit_data_master_item_merchant_pos';  
			const data_body = { 
					'id_item': id_item,
					'id_type': id_type,
					'id_unit': id_unit,
					'item_name': item_name,
					'description': description,
					'basic_price': basic_price,
					'sell_price': sell_price,
					'discount': discount,
					'tax': tax
                }
			const response = await axios({
                  method: 'post',
                  url: api,
				  headers: headers_data,
                  data: data_body
				});	
				
				if(response.data.status===200){
					GetData()
				}
				setAlerto({
				  alerto: true,
				  state_msg: response.data.message,
				  state_typ:'success'
				});
				
			setLoading(false)
        } catch (error) {
			setAlerto({
				  alerto: true,
				  state_msg: 'Data Error',
				  state_typ:'error'
				});
            console.log(error)
			setLoading(false)
        }

  };
	const handleCloseAlerto = () => {
		setAlerto(false);
	  };
	
	
  return (
    <>
	<Loader loading={loading} />
	<Alerts  alerto={alerto.alerto} state_typ={alerto.state_typ} state_msg={alerto.state_msg} onClose={handleCloseAlerto} />
	<div style={{ height: 500, width: '100%' }}>
	  <Box sx={{ '& button': { m: 1 } }}>
		  <div>
			<Button variant="outlined" onClick={()=> setOpen(true)}>Add Data</Button>
		  </div>
	  </Box>
      <DataGrid
	    loading={loading}
        rows={data}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
		  LoadingOverlay: LinearProgress,
        }}
		pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
      />
	</div>  
	  
	  <Dialog
        open={open}
		onClose={()=> setOpen(false)}
        TransitionComponent={Transition}
        
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Add Data</DialogTitle>
		<Box component="form" onSubmit={SimpanData}  >
        <DialogContent dividers>
				
			
			 <Grid container spacing={1}>
			  <Grid item xs={8}>
					<FormControl fullWidth margin="normal" size="small">
					  <InputLabel id="demo-simple-select-label">Category</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="id_type" name="id_type"
						label="Category"
					  >
					  {item_category}
						
					  </Select>
					</FormControl>
			  </Grid>
			  <Grid item xs={4}>
					<FormControl fullWidth margin="normal" size="small">
					  <InputLabel id="demo-simple-select-label">Unit Type</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="id_unit" name="id_unit"
						label="Unit Type"
					  >
					  {unit_type}
						
					  </Select>
					</FormControl>
			  </Grid>
			  <Grid item xs={12}>
				<TextField margin="normal" required fullWidth size="small" name="item_name" id="item_name" label="Item Name" autoComplete="item_name" autoFocus />
			  </Grid>
			  <Grid item xs={12}>
				<TextField margin="normal" required fullWidth size="small" 
				name="description" id="description" label="Description" 
				multiline rows={3}
				autoComplete="description" />
			  </Grid>
			  <Grid item xs={6}>
				<TextField margin="normal" required fullWidth size="small" name="basic_price" id="basic_price" label="Basic Price" autoComplete="basic_price" autoFocus />
			  </Grid>
			   <Grid item xs={6}>
				<TextField margin="normal" required fullWidth size="small" name="sell_price" id="sell_price" label="Sell Price" autoComplete="sell_price" autoFocus />
			  </Grid>
			   <Grid item xs={6}>
				<TextField margin="normal" required fullWidth size="small" name="discount" id="discount" label="Discount" autoComplete="discount" autoFocus />
			  </Grid>
			   <Grid item xs={6}>
				<TextField margin="normal" required fullWidth size="small" name="tax" id="tax" label="Tax" autoComplete="tax" autoFocus />
			  </Grid>
			</Grid>
			
			
        </DialogContent>
        <DialogActions>
			<Button variant="outlined" onClick={()=> setOpen(false)}>Close</Button>
			<Button color="success" variant="contained" type="submit">Save</Button>
        </DialogActions>
		</Box>
      </Dialog>
	  
	  <Dialog
        open={openedit}
		onClose={()=> setOpenedit(false)}
        TransitionComponent={Transition}
        
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Edit Data</DialogTitle>
		<Box  component="form"   sx={{ mt: 1 }} onSubmit={EditData}>
			<DialogContent dividers>


				<Grid container spacing={1}>
				  <Grid item xs={8}>
						<FormControl fullWidth margin="normal" size="small">
						  <InputLabel id="demo-simple-select-label">Category</InputLabel>
						  <Select
							labelId="demo-simple-select-label"
							id="id_type" name="id_type"
							label="Category"
							value={detail.id_type}  onChange={ (e) => setDetail({ ...detail, id_type: e.target.value})}
						  >
						  {item_category}
							
						  </Select>
						</FormControl>
				  </Grid>
				   <Grid item xs={4}>
					<FormControl fullWidth margin="normal" size="small">
					  <InputLabel id="demo-simple-select-label">Unit Type</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="id_unit" name="id_unit"
						label="Unit Type"
						value={detail.id_unit}  onChange={ (e) => setDetail({ ...detail, id_unit: e.target.value})}
					  >
					  {unit_type}
						
					  </Select>
					</FormControl>
			  </Grid>
				  <Grid item xs={12}>
					<TextField margin="normal" required fullWidth size="small" name="item_name" id="item_name" label="Item Name" autoComplete="item_name" autoFocus 
						value={detail.item_name}  onChange={ (e) => setDetail({ ...detail, item_name: e.target.value})}
					/>
				  </Grid>
				  <Grid item xs={12}>
					<TextField margin="normal" required fullWidth size="small" 
						name="description" id="description" label="Description" 
						value={detail.description}  onChange={ (e) => setDetail({ ...detail, description: e.target.value})}
						multiline rows={3}
						autoComplete="description" 
					/>
				  </Grid>
				  <Grid item xs={6}>
					<TextField margin="normal" required fullWidth size="small" name="basic_price" id="basic_price" label="Basic Price" autoComplete="basic_price" autoFocus 
						value={detail.basic_price}  onChange={ (e) => setDetail({ ...detail, basic_price: e.target.value})}
					/>
				  </Grid>
				   <Grid item xs={6}>
					<TextField margin="normal" required fullWidth size="small" name="sell_price" id="sell_price" label="Sell Price" autoComplete="sell_price" autoFocus 
						value={detail.sell_price}  onChange={ (e) => setDetail({ ...detail, sell_price: e.target.value})}
					/>
				  </Grid>
				   <Grid item xs={6}>
					<TextField margin="normal" required fullWidth size="small" name="discount" id="discount" label="Discount" autoComplete="discount" autoFocus 
						value={detail.discount}  onChange={ (e) => setDetail({ ...detail, discount: e.target.value})}
					/>
				  </Grid>
				   <Grid item xs={6}>
					<TextField margin="normal" required fullWidth size="small" name="tax" id="tax" label="Tax" autoComplete="tax" autoFocus 
						value={detail.tax}  onChange={ (e) => setDetail({ ...detail, tax: e.target.value})}
					/>
				  </Grid>
				</Grid>
				
				
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={()=> setOpenedit(false)}>Close</Button>
				<Button color="success" variant="contained" type="submit" >Save</Button>
			</DialogActions>
		</Box>
      </Dialog>
	  
	</>  
    
  );
}
